import React, { useState, useEffect } from 'react';

import Cookies from 'js-cookie';

import Button from '../../components/button/Button';

import Flag from '../../assets/img/flag.png';
import { Popup } from '../../utils';

const ShowLocalOffer = "showLocalOffer";

const CloseIcon = () => (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.06704 3.46655C5.84528 3.46655 5.62328 3.55103 5.45428 3.72046L3.72095 5.45379C3.38208 5.79266 3.38208 6.34131 3.72095 6.67931L10.0415 12.9999L3.72095 19.3205C3.38208 19.6593 3.38208 20.208 3.72095 20.546L5.45428 22.2793C5.79315 22.6182 6.3418 22.6182 6.6798 22.2793L13.0004 15.9587L19.3209 22.2793C19.6589 22.6182 20.2085 22.6182 20.5465 22.2793L22.2798 20.546C22.6187 20.2071 22.6187 19.6585 22.2798 19.3205L15.9592 12.9999L22.2798 6.67931C22.6187 6.34131 22.6187 5.79179 22.2798 5.45379L20.5465 3.72046C20.2076 3.38159 19.6589 3.38159 19.3209 3.72046L13.0004 10.041L6.6798 3.72046C6.51037 3.55103 6.2888 3.46655 6.06704 3.46655Z" fill="currentColor" />
    </svg>
);

const temp = '069c8bebc2c34f26b137bb601719eb5f';



const CoffeeNotification: React.FC = () => {

    // get is from cookies (showLocalOffer)
    const [showNotification, setShowNotification] = useState<boolean>(false);

    useEffect(() => {
        const showLocalOffer = Cookies.get(ShowLocalOffer)
        if (showLocalOffer) {
            setShowNotification(showLocalOffer === 'true');
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${temp}`);
                const data = await response.json();
                if (data.state_code.toLowerCase().includes('or') || data.state_code.toLowerCase().includes('wa')) { 
                    setShowNotification(true);
                }
            } catch (error) {
                console.error('Error fetching IP data:', error);
            }
        };
        if (!Cookies.get(ShowLocalOffer)){
            fetchData();    
        }
        
    }, []);

    const handleCloseNotification = () => {
        setShowNotification(false);
        Cookies.set(ShowLocalOffer, 'false', { expires: 5 });
    };

    return (
        <div>
            {showNotification && (
                <div className="notification-bar">
                    <div className="notification-wrapper">

                        <div className="notification-message">
                            <div className="notification-content">
                                <div>
                                    <img src={Flag} alt="Flag" className="flag" />

                                </div>
                                <p className="body4">Are you near <span className="tertiary bold">Portland OR</span>? Let’s meet for a coffee and talk business - <span className="primary bold">coffee’s</span> on me!</p>
                            </div>

                        <Popup label='Free Coffee' calLink='verdix/coffee-talk' />
                    </div>
                    <button className="close-icon" onClick={handleCloseNotification} type="button" aria-label="Close Notification">
                        <CloseIcon />
                    </button>
                    </div>
                </div>
            )}
        </div>);
};

export default CoffeeNotification;