
const Quotes = ({className}: {className: string}) => (
<svg className={className} width="38" height="31" viewBox="0 0 38 31" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_245_519)">
<path fillRule="evenodd" clipRule="evenodd" d="M21.1102 11.3955L21.1102 26.1672C21.1102 28.4981 22.9998 30.3877 25.3307 30.3877L33.7717 30.3877C36.1026 30.3877 37.9922 28.4981 37.9922 26.1672L37.9922 17.7262C37.9922 15.3953 36.1026 13.5057 33.7717 13.5057L27.4409 13.5057L27.4409 11.3955C27.4018 9.35143 28.8332 7.57318 30.8384 7.17491C31.3063 7.06979 31.6443 6.66231 31.6614 6.18314L31.6614 1.96257C31.6771 1.63514 31.5455 1.31769 31.3027 1.09738C31.0782 0.897386 30.7778 0.804893 30.4797 0.844207C25.1182 1.44835 21.0762 6.00005 21.1102 11.3955ZM12.6687 30.3877L4.22766 30.3877C1.89683 30.3877 0.00719087 28.4981 0.00719107 26.1672L0.00719236 11.3955C-0.0158823 6.00812 4.02321 1.46858 9.37676 0.865287C9.67485 0.825973 9.97521 0.918466 10.1997 1.11855C10.4257 1.32424 10.5558 1.61482 10.5585 1.92041L10.5585 6.14088C10.5607 6.636 10.2184 7.06599 9.73543 7.17491C7.73022 7.57318 6.29878 9.35143 6.338 11.3955L6.338 13.5057L12.6687 13.5057C14.9996 13.5057 16.8892 15.3953 16.8892 17.7262L16.8892 26.1672C16.8892 28.4981 14.9996 30.3877 12.6687 30.3877Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_245_519">
<rect width="38" height="30.3877" fill="currentColor" transform="translate(38 30.3877) rotate(-180)"/>
</clipPath>
</defs>
    </svg>
);

export default Quotes;
