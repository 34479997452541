import React from 'react';

const PrivacyPolicy: React.FC = () => (
        <div>
            <h1>Privacy Policy</h1>
            <p>
                This privacy policy outlines how we collect, use, and protect your personal information when you visit our website. By using our website, you consent to the terms of this privacy policy.
            </p>
            <h2>Information We Collect</h2>
            <p>
                We may collect certain information about your visit to our website, including your IP address, browser type, operating system, and pages visited. This information is collected through the use of cookies and similar technologies.
            </p>
            <h2>Google Analytics</h2>
            <p>
                We use Google Analytics to analyze the usage of our website. Google Analytics collects information such as your IP address, browser type, operating system, and pages visited. This information is used to generate statistical reports about website traffic and user behavior. Google Analytics may also use cookies and similar technologies to collect and store this information.
        </p>
        <h2>Heap Analytics</h2>
        <p>
            We use Heap Analytics to analyze the usage of our website. Heap Analytics collects information such as your IP address, browser type, operating system, and pages visited. This information is used to generate statistical reports about website traffic and user behavior. Heap Analytics may also use cookies and similar technologies to collect and store this information.
        </p>
            <h2>How We Use Your Information</h2>
            <p>
                The information we collect is used to improve the functionality and performance of our website. We may also use this information to personalize your experience, provide targeted advertisements, and analyze user behavior.
            </p>
            <h2>How We Protect Your Information</h2>
            <p>
                We take appropriate measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.
            </p>
            <h2>Changes to This Privacy Policy</h2>
            <p>
                We reserve the right to update or modify this privacy policy at any time. Any changes will be effective immediately upon posting the updated privacy policy on our website. We encourage you to review this privacy policy periodically for any changes.
            </p>
            <h2>Contact Us</h2>
            <p>
                If you have any questions or concerns about our privacy policy, please contact us at any contact information provided on our website.
            </p>
        </div>
    );

export default PrivacyPolicy;