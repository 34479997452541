import { FC } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./app.scss";
import BasicLayout from "./layout/Basic";
import Landing from './pages/landing/Container'
import Price from "./pages/price/Price";
import PrivacyPolicy from './pages/privacy/PrivacyPolicy'
import ThankYou from "./pages/thankYou/ThankYou";
import ThankYouForm from "./pages/thankYou/ThankYouForm";

const App: FC = () => (
  <Router>
    <div>
      <BasicLayout>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/thank-you-form" element={<ThankYouForm />} />
          <Route path="/price" element={<Price />} />
          <Route path="*" element={<Landing />} />
        </Routes>
      </BasicLayout>
    </div>
  </Router>
);

export default App;
