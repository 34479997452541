import Button from '../../components/button/Button';
import { Popup } from '../../utils';

const Intro = () => {
    const handleSecondaryClick = () => {
        const portfolioElement = document.getElementById('portfolio');
        if (portfolioElement) {
            portfolioElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div id='intro'>
            <h1>Unlock Your <span className='secondary'>Digital Potential:<br /></span> <span className="tertiary">Web Design</span> to <span className="quaternary">Full-Funnel Marketing</span></h1>
            <h2>Web Design and Digital Marketing Solutions to Boost Your Online Success</h2>
            <div className="align-center">
                <Popup />
                <Button className='secondary-action' label='Our Works' btnType='outlined' color='text' onClick={handleSecondaryClick} />
            </div>
        </div>
    );
};

export default Intro;
// Unlock Your Digital Potential: From Web Design to Full-Funnel Marketing