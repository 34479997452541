import FuncIMG from '../../assets/img/you-get/func.svg'; 
import LoveIMG from '../../assets/img/you-get/love.svg';
import SecIMG from '../../assets/img/you-get/sec.svg';
import Button from '../../components/button/Button';

const YouGet = () => {
    
    const handleButtonClick = () => {
        const catElement = document.getElementById('cat');
        if (catElement) {
            catElement.scrollIntoView({ behavior: 'smooth' });
        }
    }
        
    return (
        <div id="you-get">
            <p className="body3">
                What <span className="tertiary medium">you get</span>
            </p>
            <div className="cards">
                <div className="card primary" >
                    <img src={FuncIMG} alt="functionality" />
                    <p className="body4 ">Unparalleled Functionality</p>
                    <p className="body5">No cookie-cutter designs; we create custom designs tailored to your specific needs with impeccable user experience.</p>
                </div>
                <div className="card secondary" >
                    <img src={SecIMG} alt="functionality" />
                    <p className="body4 ">Scalability and<br />Future-Proof Security</p>
                    <p className="body5">Create platform for endless possibilities, accommodating future ideas and enhancements.</p>
                </div>
                <div className="card quaternary" >
                    <img src={LoveIMG} alt="functionality" />
                    <p className="body4 ">Design Your Customers
                        Will Love</p>
                    <p className="body5">We deliver aesthetic and stunning designs that attract, convert, and delight users. So you can achieve all your marketing goals.</p>
                </div>
            </div>
            <div className="button" >
                <Button label="Let' s connect, convert, and succeed" color="primary" btnType='filled' onClick={handleButtonClick} />
            </div>
        </div>
    )
}

export default YouGet;