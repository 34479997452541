import ArrowIcon from "../../assets/img/Arrow";
import Accordion from "../../components/accordion/Accordion";

type FAQProps = {
    question: string;
    answer: string;
    color: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
}

const faqs: FAQProps[] = [
    {
        question: "How long will it take to create a website using template?",
        answer: "Creating a website, even with a ready-made solution, is a process that depends on several factors, and each of them can influence the duration of the work. The creation of website itself can be complete withing 2 weeks, however we build not just websites, but brands and that requires more time from 1 to 3 months.",
        color: "primary"
    },
    {
        question: "How can I find out the final cost of development?",
        answer: "The final cost of development depends on the complexity of the project, the number of pages, and the number of unique features. We usually start at +$900 but need more the details of your project to give you correct estimates.",
        color: "primary"
    },
    {
        question: "What types of services do you provide after the website is launched?",
        answer: "After launch we make sure that website works as needed and there are no technical issues, we provide educational materials on how to use and update website (if content managment was in scope). Depending on budget and situation we can help with hosting and domain management, SEO, and other services. Always happy to scale your website with new features and improvements.",
        color: "secondary"
    },
    {
        question: "How will you ensure the security of my website?",
        answer: "We take website security very seriously. We use the latest security measures to protect your website from hackers and other threats.",
        color: "secondary"
    },
    {
        question: "Which content management system will my website use?",
        answer: "We create custom admin panel tailored to your needs. We can also use Former if a lot of content managment is needed. We will help you choose the best solution for your website based on your requirements and budget.",
        color: "quaternary",
    },
    {
        question: "Will my website be compatible with mobile devices?",
        answer: "Yes, all websites we create are fully responsive and compatible with all modern devices, including smartphones, tablets, and desktop computers.",
        color: "quaternary"
    }
]

const FAQ = () => (
        <div id="faq">
        <p className="body3">FAQ</p>
        <div className="faqs">
            {faqs.map((faq, index) => (
                <Accordion key={index} question={faq.question} answer={faq.answer} color={faq.color} />
            ))}
        </div>
        </div>
    )
    
export default FAQ;