// https://res.cloudinary.com/dgdvy66ao/video/upload/v1718409892/My_Video_occzxj.mp4
import React from 'react';
import './Video.css';

const Video = () => (
    <div className="video" >
        <div className="decorative-squares">
            <div  />
            <div  />
            <div  />
            <div  />
        </div>

            <iframe
                src="https://player.cloudinary.com/embed/?public_id=Intro_2_jl3sai&cloud_name=dgdvy66ao&player[posterOptions][transformation][start_offset]=4&player[controls]=true&player[showLogo]=false&player[fluid]=true"
                width="640"
                height="360"
                allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
                // style="height: auto; width: 100%; aspect-ratio: 640 / 360;"
                style={{ height: "auto", width: "100%", aspectRatio: "640 / 360" }}
                // allowfullscreen
                allowFullScreen
                frameBorder="0"
                title="Intro Video"
        />
        <script src="https://player.vimeo.com/api/player.js" />
        </div>
    );


export default Video;