import Quotes from '../../assets/img/Quotes';

import MarkIMG from '../../assets/img/reviews/mark.png';
import GeorgeIMG from '../../assets/img/reviews/george.png';

type Review = {
    text: string,
    avatar: string,
    name: string,
    title: string,
    color: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
}

const reviews: Review[] = [
    {
        text: "Everything is great, the website turned out exactly as I wanted. Two months after starting to use it, my sales have increased manifold!",
        avatar: MarkIMG,
        name: "Mark",
        title: "Content Maker",
        color: 'primary',
    },
    {
        text: "Fantastic customer service. I have been very satisfied with the website. The team was a joy to work with and we are very happy with the end result.",
        avatar: GeorgeIMG,
        name: "George",
        title: "Educator",
        color: 'secondary',
    }
]

const Reviews = () => (
    <div id="reviews">
        <p className="body3">Reviews</p>
        <div className="list">
            {reviews.map((review, index) => (
                <div className={`item ${review.color}`} key={index}>
                    <div>

                        <Quotes className={review.color} />
                    </div>
                    <div className="review">
                        <p className="body5">{review.text}</p>
                        <div className="avatar">
                            <img src={review.avatar} alt="avatar" />
                            <div className="title">
                                <p className="body5">{review.name}</p>
                                <p className="body6">{review.title}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
)

export default Reviews;