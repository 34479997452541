import Nav from "../components/nav/Nav"

import './basic.scss'
import Footer from "./Footer"

type Props = {
    children: React.ReactNode
}

const BasicLayout = ({ children }: Props) => (
    <div className="root">

        <div className="main">
            <header>
                <Nav />
            </header>
            <main>
                {children}
            </main>
    <Footer />
        </div>
    </div>
    )   

export default BasicLayout