import { useEffect, useRef, useState } from 'react'
import { Engine, Render, Bodies, World, Mouse, MouseConstraint } from 'matter-js'

import ThumbSVG from '../../assets/img/qualitites/thumb.svg'
import PoundSVG from '../../assets/img/qualitites/pound.svg'
import AtSVG from '../../assets/img/qualitites/at.svg'
import FoldersSVG from '../../assets/img/qualitites/folders.svg'
import ShareSVG from '../../assets/img/qualitites/share.svg'

import SaveSVG from '../../assets/img/qualitites/save.svg'
import UniqueSVG from '../../assets/img/qualitites/unique.svg'
import FutureSVG from '../../assets/img/qualitites/future.svg'
import EasySVG from '../../assets/img/qualitites/easy.svg'
import AdapriveSVG from '../../assets/img/qualitites/adaptive.svg'


const Comp = (props) => {

    const [cw, setCw] = useState(document.documentElement.clientWidth)
    
    const ch = 400
    const scene = useRef()
    const engine = useRef(Engine.create({
        gravity: {
            scale: 0.00003
        }
    }))
    useEffect(() => {
        const handleResize = () => {
            let newCw = document.documentElement.clientWidth
            if (newCw > 1120) {
                newCw = 1120
            } else if (newCw < 420) {
                newCw = 300
            } else {
                newCw *= 0.9
            }
            setCw(newCw)
        }
        handleResize()
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        const render = Render.create({
            element: scene.current,
            engine: engine.current,
            options: {
                width: cw,
                height: ch,
                wireframes: false,
                background: 'transparent'
            }
        })

        const mouse = Mouse.create(render.canvas)
        const mouseConstraint = MouseConstraint.create(engine.current, {
            mouse,
            constraint: {
                stiffness: 2,
                render: {
                    visible: false
                }
            }
        })

        mouseConstraint.mouse.element.removeEventListener("mousewheel", mouseConstraint.mouse.mousewheel);
        mouseConstraint.mouse.element.removeEventListener("DOMMouseScroll", mouseConstraint.mouse.mousewheel);

        World.add(engine.current.world, mouseConstraint)
        let scaleFactor = 1; // Default scale factor
        if (cw <= 600) {
            scaleFactor = 0.6; // Scale factor for mobile screens
        } else if (cw <= 900) {
            scaleFactor = 0.75; // Scale factor for tablet screens
        }
        
        World.add(engine.current.world, [
            Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true, render: { visible: false } }),
            Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true, render: { visible: false } }),
            Bodies.rectangle(cw, ch / 2, 20, ch, { isStatic: true, render: { visible: false } }), // right
            Bodies.rectangle(cw / 2, ch + 10, cw, 20, { isStatic: true, render: { visible: false } }), // bottom

            Bodies.rectangle(400 * scaleFactor, 0, 329 * scaleFactor, 80 * scaleFactor, {
                render: {
                    sprite: {
                        texture: SaveSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    },
                },
                angle: Math.PI / 8, // Add rotation angle in radians
            }),
            Bodies.rectangle(400 * scaleFactor, 100, 233 * scaleFactor, 67 * scaleFactor, {
                render: {
                    sprite: {
                        texture: UniqueSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    },
                },
                angle: - Math.PI / 8, // Add rotation angle in radians
            }),
            Bodies.rectangle(400 * scaleFactor, 200, 367 * scaleFactor, 67 * scaleFactor, {
                render: {
                    sprite: {
                        texture: FutureSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    },
                },
                angle: Math.PI / 8, // Add rotation angle in radians
            }),
            Bodies.rectangle(400 * scaleFactor, 300, 191 * scaleFactor, 67 * scaleFactor, {
                render: {
                    sprite: {
                        texture: EasySVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    },

                },
                angle: - Math.PI / 8, // Add rotation angle in radians
            }),
            Bodies.rectangle(400 * scaleFactor, 300, 365 * scaleFactor, 67 * scaleFactor, {
                render: {
                    sprite: {
                        texture: AdapriveSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    },
                },
                angle: Math.PI / 8, // Add rotation angle in radians
            }),

            Bodies.circle(400 * scaleFactor, 100, 30 * scaleFactor, {
                mass: 0.5,
                restitution: 0.9,
                friction: 0.15,
                render: {
                    sprite: {
                        texture: ThumbSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    }
                }
            }),
            Bodies.circle(400 * scaleFactor, 200, 30 * scaleFactor, {
                mass: 0.5,
                restitution: 0.9,
                friction: 0.15,
                render: {
                    sprite: {
                        texture: PoundSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    }
                }
            }),
            Bodies.circle(400 * scaleFactor, 250, 30 * scaleFactor, {
                mass: 0.5,
                restitution: 0.9,
                friction: 0.15,
                render: {
                    sprite: {
                        texture: AtSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    }
                }
            }),
            Bodies.circle(400 * scaleFactor, 350, 30 * scaleFactor, {
                mass: 0.5,
                restitution: 0.9,
                friction: 0.15,
                render: {
                    sprite: {
                        texture: FoldersSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    }
                }
            }),
            Bodies.circle(400 * scaleFactor, 300, 30 * scaleFactor, {
                mass: 0.5,
                restitution: 0.9,
                friction: 0.15,
                render: {
                    sprite: {
                        texture: ShareSVG,
                        xScale: scaleFactor, // Apply scale factor to width
                        yScale: scaleFactor, // Apply scale factor to height
                    }
                }
            })

        ])

        Engine.run(engine.current)
        Render.run(render)

        const handleMouseMove = (event) => {
            const { clientX, clientY } = event
            const rect = render.canvas.getBoundingClientRect()
            const mouseX = clientX - rect.left
            const mouseY = clientY - rect.top

            if (
                mouseX < 0 ||
                mouseX > cw ||
                mouseY < 0 ||
                mouseY > ch
            ) {
                // Cursor is outside the boundaries of the simulation
                // Release the object
                mouseConstraint.constraint.bodyB = null
            }
        }

        const currentScene = scene.current;
        currentScene.addEventListener('mousemove', handleMouseMove)

        
        return() => {
            currentScene.removeEventListener('mousemove', handleMouseMove)
            Render.stop(render)
            World.clear(engine.current.world)
            const currentEngine = engine.current;
            Engine.clear(currentEngine)
            render.canvas.remove()
            render.canvas = null
            render.context = null
            render.textures = {}
        }
    }, [cw])


    return (
        <div>
            {/* @ts-ignore */}
            <div ref={scene} style={{ width: '100%', height: '100%' }} />
        </div>
    )
}

export default Comp
