import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';

import './styles.scss'

const ThankYou: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const paramName = params.get('name');
    const navigate = useNavigate()

    return (
        <div className="thankyou-root">
            <div className="header">
                <h1><span className="secondary">Thank</span> You{paramName && <>,<span className="primary">{` ${paramName}`}</span>!</>}</h1>
            </div>
            <p>Your message was successfully sent. We really appreciate your time and looking forward talking with you</p>
            <Button label="Go Home" btnType="filled" color="primary" onClick={() => navigate('/')} />
        </div>
    );
}

export default ThankYou;