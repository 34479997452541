import LineIMG from '../../assets/img/steps/line.png';
import MobileLineIMG from '../../assets/img/steps/line-mobile.png';
import OneIMG from '../../assets/img/steps/1.svg';
import TwoIMG from '../../assets/img/steps/2.svg';
import ThreeIMG from '../../assets/img/steps/3.svg';
import FourIMG from '../../assets/img/steps/4.svg';


const Steps = () => (
    <div id="steps">
        <p className="body3 text">
            The digital landscape is filled with dull, uninspiring, and generic websites. We are here to help you <span className="primary medium">stand out</span> and <span className="tertiary medium">earn</span> your client&apos;s trust. Years of experience distilled into <span className="quaternary medium"> four transparent steps</span> 
        </p>
        <div className="timeline">
            <img src={LineIMG} className='line' alt="line" />
            <img src={MobileLineIMG} className='line-mobile' alt="line" />
            <div className="step step-1">
                <img src={OneIMG} alt="step 1" />
                <p className="body4 bold tertiary">1. Investigation</p>
                <p className="body5">Work together with you to better understand your company, goals, customers, and competition.</p>
            </div>    
            <div className="step step-2">
                <img src={TwoIMG} alt="step 1" />
                <p className="body4 bold primary">2. Content creation</p>
                <p className="body5">Craft concise, captivating content for your clients..</p>
            </div>    
            <div className="step step-3">
                <img src={ThreeIMG} alt="step 1" />
                <p className="body4 bold quaternary">3. Design</p>
                <p className="body5">Merge art and marketing to envision your future website.</p>
            </div>    
            <div className="step step-4">
                <img src={FourIMG} alt="step 1" />
                <p className="body4 bold secondary">4. Development</p>
                <p className="body5">After rigorous quality control and assurance processes, deliver the final product on time and on-budget.</p>
            </div>    
        </div>
    </div>
)

export default Steps;