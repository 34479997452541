import { is } from '@react-spring/shared';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/button/Button';
import sendFeedback from '../sendFeedback';

import './styles.scss'

// const getAllUrlParams = () => {
//     const params = new URLSearchParams(window.location.search);
//     const paramsObj: { [key: string]: string | string[] } = {};

//     Array.from(params.entries()).forEach(([key, value]) => {
//         if (paramsObj[key]) {
//             // If the key already exists, convert it to an array
//             // @ts-ignore
//             paramsObj[key] = [].concat(paramsObj[key], value);
//         } else {
//             paramsObj[key] = value;
//         }
//     });

//     return paramsObj;
// };


const ThankYou: React.FC = () => {
    const params = new URLSearchParams(window.location.search);
    const paramName = params.get('name');
    const paramEmail = params.get('email');
    const paramDescription = params.get('description');

    const navigate = useNavigate()

    useEffect(() => {
        if (paramEmail) {
            sendFeedback({
                data: {
                    name: paramName || '',
                    email: paramEmail || '',
                    message: paramDescription || '',
                    is_scheduled: true
                }
            })            
        }
    }, [])

    return (
        <div className="thankyou-root">
            <div className="header">
                <h1><span className="secondary">Thank</span> You{paramName && <>,<span className="primary">{` ${paramName}`}</span>!</>}</h1>
            </div>
            <p>Your booking is successfully confirmed. We really appreciate your time and looking forward talking with you</p>
            <Button label="Go Home" btnType="filled" color="primary" onClick={() => navigate('/')} />
        </div>
    );
}

export default ThankYou;