/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import {useState} from "react";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "../../components/button/Button";
import sendFeedback, { Data } from "../sendFeedback";

function checkEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

type Props = {
    description?: string;
    subdescription?: string;
}

const CAT = ({ description, subdescription }: Props)  => {

    const { register, handleSubmit, formState } = useForm<Data>();
    const navigate = useNavigate();
    const [notification, setNotification] = useState("");
    const [loading, setLoading] = useState(false);
    const [notificationType, setNotificationType] = useState("");

    const onSubmit = async (data: Data) => {
        setLoading(true);
        setNotification("");
        setNotificationType("");
        const isSuccess = await sendFeedback({
            data
        });
        if (isSuccess) {
            setNotification("Feedback sent successfully");
            setNotificationType("success");
            navigate("/thank-you-form");
        } else {
            setNotification("Failed to send feedback");
            setNotificationType("error");
        }
        setLoading(false);
    }


    return (
        <div id="cat">
            {notification && notificationType && <div className={`notification ${notificationType}`}>{notification}</div>}
            {description ? (
                <>
                    <p className="body4">{description}</p>
                    {subdescription && <p className="body3" >{subdescription}</p>}
                </>
                ) : 
                (<>
                <p className="body3"><span className="primary medium">Let us do</span> the hard work for you!</p>
                <p className="body4">If you have any questions - contact us by filling the form below</p>
                </>)
            }
            
            <div className="wrapper">
                <form className="contact" onSubmit={handleSubmit(onSubmit)}>
                    <div className="input-wrapper">
                        <label htmlFor="name">Name</label>
                        <input type="text" id="name" placeholder="Name" {...register("name")} />
                    </div>
                    <div className="input-wrapper">
                        <div className="label-wrapper">

                            <label htmlFor="email">Email</label>
                            {formState.errors.email
                                && <p className="required">Required</p>}
                           
                        </div>
                        <input type="email" id="email" placeholder="Email" {...register("email", { required: true, validate: checkEmail })} />
                    </div>
                    <div className="input-wrapper">
                        <label htmlFor="message">Message</label>
                        <textarea placeholder="Message" id="message" {...register("message")} />
                    </div>

                    <div className="button-wrapper">
                        <Button color="primary" btnType="filled" label="Contact Us" disabled={loading} loading={loading} type="submit" />
                    </div>
                </form>
            </div>

        </div>
    )
}
    
export default CAT;
