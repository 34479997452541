import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import CAT from "./CAT";
import CoffeeNotification from "./Coffee";

import Demo from "./Demo";
import FAQ from "./FAQ";
import Intro from "./Intro";
import Numbers from "./Numbers";
import OurServices from "./OurServices";
import Portfolio from "./Portfolio";
import Qualities from "./Qualities";
import Reviews from "./Reviews";
import Steps from "./Steps";
import './style.scss'
import Video from "./Video";
import YouGet from "./YouGet";

const DELAY = 100;

const Container = () => {
    const location = useLocation();
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1, // Adjust this threshold as per your requirement
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setTimeout(() => {
                        entry.target.classList.add("reveal"); // Add a CSS class to reveal the element
                    }, DELAY); // Adjust the delay (in milliseconds) as per your requirement
                }
            });
        }, options);

        const elements = document.querySelectorAll(".reveal-on-scroll");
        elements.forEach((element) => {
            observer.observe(element);
        });

        return () => {
            elements.forEach((element) => {
                observer.unobserve(element);
            });
        };
    }, []);
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className="landing">
            <CoffeeNotification />
            <div className="reveal-on-scroll">
                <Intro />
            </div>
            <div className="reveal-on-scroll">
                <Video />
            </div>
            <div className="reveal-on-scroll">
                <Numbers />
            </div>
            <div className="reveal-on-scroll">
                <Qualities />
            </div>
            <div className="reveal-on-scroll">
                <Demo />
            </div>
            <div className="reveal-on-scroll">
            <Steps />
            </div>
            <div className="reveal-on-scroll">
            <YouGet />
            </div>
            <div className="reveal-on-scroll">
            <OurServices />
            </div>
            <div className="reveal-on-scroll">
                <Portfolio />
            </div>
            <div className="reveal-on-scroll">
            <Reviews />
            </div>
            <div className="reveal-on-scroll">
            <FAQ />
            </div>
            <div className="reveal-on-scroll">
            <CAT />
            </div>
        </div>
    );
};

export default Container;