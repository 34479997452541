import React from 'react'

import CAT from '../landing/CAT';
import './Price.scss'

const PriceBox = () => (
    <>
          <h1>Price</h1>
          <div className="box">
              <div className="row">
                  <p className="description">
                      Consultation
                  </p>
                  <p className="price">
                      $70/h
                  </p>
              </div>
              <div className="row">
                  <p className="description">
                    Design Only for Landing Page
                  </p>
                  <p className="price">
                      from $400
                  </p>
              </div>
              <div className="row">
                  <p className="description">
                    Landing Page Full Package
                  </p>
                  <p className="price">
                      from $700
                  </p>
              </div>
              <div className="row">
                  <p className="description">
                    Design Only for Multipage Page Website
                  </p>
                  <p className="price">
                      from $1100
                  </p>
              </div>
              <div className="row">
                  <p className="description">
                    Multipage Page Website Full Package
                  </p>
                  <p className="price">
                      from $1600
                  </p>
              </div>
        </div>
    </>
)

const Price = () => (
    <div id="price" >
        <PriceBox />
        <CAT description='Do you want free and precise quote for your project?' />
      </div>
  )

export default Price;