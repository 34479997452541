import { useState, useRef, useEffect } from 'react';

import ArrowIcon from "../../assets/img/Arrow";

type AccordionProps = {
    key: number;
    question: string;
    answer: string;
    color: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
}

const Accordion: React.FC<AccordionProps> = ({ question, answer, color }) => {
    const [expanded, setExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentHeight, setContentHeight] = useState<number | null>(null);

    useEffect(() => {
        if (contentRef.current) {
            setContentHeight(expanded ? contentRef.current.scrollHeight : null);
        }
    }, [answer, expanded]);

    const toggleAccordion = () => {
        setExpanded(!expanded);
    };

    return (
        <div
            onClick={toggleAccordion}
            onKeyDown={toggleAccordion}
            tabIndex={0}
            role='button'
            className={`faq-item ${color} ${expanded ? 'expanded' : ''}`}>
            <div className="question" >
                <p className="body4">{question}</p>
                <div>
                <ArrowIcon isOpen={expanded} />
                </div>
            </div>
            <div
                className={`answer ${expanded ? 'expanded' : ''}`}
                style={{ height: expanded ? `${contentHeight}px` : '0' }}
                ref={contentRef}>
                <p className="body5">{answer}</p>
            </div>
        </div>
    );
};

export default Accordion;
