const OurServices = () => (
    <div id="our-services">
        <p className="body3">Explore <span className="quaternary">our services</span></p>
        <div className="list">
            <div className="item">
                <p className="body4">Custom Web Design and Development</p>
                <div className="tags tags-primary">
                    <div className="tag">Tailored Web Solutions</div>
                    <div className="tag">UX/UI Design</div>
                    <div className="tag">Professional Coding</div>
                </div>
            </div>
            <div className="item">
                <p className="body4">Complete Digital Marketing Solutions</p>
                <div className="tags tags-secondary">
                    <div className="tag">SEO & Content Strategy</div>
                    <div className="tag">Social Media Management</div>
                    <div className="tag">PPC Advertising</div>
                </div>
            </div>
            <div className="item">
                <p className="body4">Integrated Brand Strategy and Analytics</p>
                <div className="tags tags-tertiary">
                    <div className="tag">Brand Identity Development</div>
                    <div className="tag">Market Positioning</div>
                    <div className="tag">Performance Analytics</div>
                </div>
            </div>
        </div>
    </div>
);

export default OurServices;
