function checkEmail(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export type Data = {
    name: string;
    email: string;
    message: string;
    is_scheduled?: boolean;
};

// const URL = 'http://localhost:8000/';
// const URL = 'https://verdix.onrender.com/';
const URL = 'https://api.verdix.io/';
type Props = {

    data: Data;
};

const sendFeedback = async ({ data }: Props): Promise<boolean> => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };

        const response = await fetch(`${URL}feedback/`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        });

        if (!response.ok) {
            throw new Error('Failed to send feedback');
        }

        // redirect to thank you page
        // window.location.href = '/thank-you-form';
        try {
            // @ts-ignore
            gtag_report_conversion();
            // @ts-ignore
            heap.identify(data.email);
        } catch (error) {
            console.error('Error GTA:', error);
        }
        return true;
    } catch (error) {

        console.error('Error:', error);
        return false;
    } 
}



export default sendFeedback;