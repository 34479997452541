import Button from "../button/Button"
import Logo from '../../assets/img/logo.svg'
import LogoSmall from '../../assets/img/logo-small.svg'

import './nav.scss'
import { useNavigate } from "react-router-dom"

import Dollar from "../../assets/img/Dollar"

const Nav = () => {
   const navigate = useNavigate();
   return(
   <nav>
      <a className="logo" href='#' onClick={(e) => {e.preventDefault(); navigate('/')}}>
                        
         <img src={Logo} alt="" />
      </a>
      <a className="logo-small" href='#' onClick={(e) => {e.preventDefault(); navigate('/')}}>
         <img src={LogoSmall} alt="" />
      </a>
      <div className="nav-panel">
            <Button label='Price' onClick={() => navigate('price')} btnType='link' color="text" className="price" />
            <a className="price-small" href='#' onClick={(e) => { e.preventDefault(); navigate('/price') }}>
               <Dollar />
            </a>
            <Button label='Contact us' color="text" btnType='outlined' onClick={() => { navigate('/#cat') }} />
      </div>
        
   </nav>
)}
    export default Nav