import React from 'react';

import Logo from '../assets/img/logo.svg'
import InstagramLogo from '../assets/img/instagram.png'
import LinkedIn from '../assets/img/linkedin.png'
import Youtube from '../assets/img/youtube.png'

const Footer: React.FC = () => (
        <footer>
            <div className="first-level">
                <img src={Logo} alt="" />
                <div className="links">
                    <a href='#reviews'>Reviews</a>
                    <a href='#faq'>FAQ</a>
                    <a href='#steps'>Steps</a>
                    <a href='#our-services'>Services</a>
                    <a href='#qualities'>Qualities</a>
            </div>
            <div className="social">
                <a href='https://www.instagram.com/verdix.io/'><img src={InstagramLogo} alt="" /></a>
                <a href='https://www.linkedin.com/company/90649459'><img src={LinkedIn} alt="" /></a>
                <a href='https://www.youtube.com/channel/UCETA8juPkI2kwkHxUxb2f_A'><img src={Youtube} alt="" /></a>
            </div>
            </div>
            <div className="second-level">
                <a href='#'>@VERDIX ALL RIGHTS RESERVED</a>
                <a href='/privacy-policy'>PRIVACY POLICY</a>
                <a href='#'>Portland Oregon</a>
        </div>
        </footer>
    );

export default Footer;