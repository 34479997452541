import CountUp from "react-countup";

import Button from "../../components/button/Button";

const Numbers = () => (
    <div id="numbers">
        <div className="content">
            <div className="header">
                <p className="body3"><span className="secondary bold">Boosting</span> online presence,<br/><span className="quaternary bold">minimizing</span>  effort</p>
            </div>
            <div className="points">
                <div className="point">
                    <p className="number body1 tertiary medium"><CountUp end={81} duration={5} />%</p>
                    <p className="body5">Of customers research online before buying</p>
                </div>
                <div className="point">
                    <p className="number body1 quaternary medium"><CountUp end={8} delay={0.5} duration={5} /> sec</p>
                    <p className="body5">Grab the prospect&apos;s attention</p>
                </div>
                <div className="point">
                    <p className="number body1 secondary medium"><CountUp end={450} delay={1} duration={5} />%</p>
                    <p className="body5">Website creation boosts sales</p>
                </div>
            </div>
        </div>

        </div>
)
    
export default Numbers;