import './button.scss';

type ButtonProps = {
  btnType: 'filled' | 'outlined' | 'link';
  color: 'primary' | 'secondary' | 'text' | 'tetrinary' | 'quaternary';
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  type?: 'button' | 'submit' | 'reset';
} & React.HTMLAttributes<HTMLButtonElement>;

const Spinner = () => (
  <div className="spinner">
    <div />
    <div />
    <div />
    <div />
  </div>
);

const Button = ({ btnType, color, onClick, label, className, loading, type, disabled, ...rest }: ButtonProps) => (
  <button type={type || 'button'} className={`button ${btnType} ${color} ${className||''}`} disabled={disabled} onClick={onClick} {...rest}>
    {loading? <Spinner/> : label}
  </button>
);

Button.defaultProps = {
  onClick: () => { /* Add your implementation here */ },
  disabled: false,
  loading: false,
  type: 'button',
};

export default Button;
