import { type } from "os";

import { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

import Button from "./components/button/Button";

const goToCAT = () => {
    window.open('/#cat', "_self")
}

type Props = {
    calLink?: string
    label?: string
}
const Popup = ({ calLink, label }: Props) => {
    useEffect(() => {
        (async function () {
            const cal = await getCalApi();
            cal("ui", {
                // "cssVarsPerTheme": { "dark": { "--primary": "#FF0000" }, "light": { "--primary": "#0000FF" } }, "theme": "dark",
                "hideEventTypeDetails": false, "layout": "month_view"
            });
        })();
    }, [])
    return (
        <Button
            data-cal-namespace=""
            data-cal-link={calLink || "verdix/discovery"}
            data-cal-config='{"layout":"month_view"}'
            label={label || "Get Free Consultation"}
            btnType="filled"
            color="primary" />
    )
}

Popup.defaultProps = {
    calLink: "verdix/discovery",
    label: "Get Free Consultation"
}

export {
    Popup,
    goToCAT
        }