/* eslint-disable react/no-array-index-key */

import GeorgePNG from '../../assets/img/portfolio/george.png'
import YoutubePNG from '../../assets/img/portfolio/youtube.png'

const portfolio = [
    {
        url: 'https://www.behance.net/gallery/193523817/George-Generate',
        color: 'quaternary',
        img: GeorgePNG,
        name: 'George Generate',
        description: 'George Generate is a versatile, creative individual involved in multiple projects simultaneously. The task was to reflect all aspects of his activities so that each page would serve as a separate landing page for each project.',
        tags: [
            'Online Education',
            'Payment integration',
            'Work showcase',
            'AI',
        ]
    },
    {
        url: 'https://markavrely.com/',
        color: 'primary',
        img: YoutubePNG,
        name: 'YouTube Master',
        description: 'Mark Avrely is a YouTube Master who helps people to grow their channels. The task was to create portfolio website with dynamic content and CRM system. The website is SEO optimized.',
        tags: [
            'Portfolio',
            'Dynamic content',
            'CRM',
            'SEO',
        ]
    }
]

const Portfolio = () => (
      <div id="portfolio">
        <p className='body3'>Portfolio</p>
        {
            portfolio.map((item, index) => (
                <div key={index} className="portfolio-item">
                    <a href={item.url} className="link">
                        <div className={`circle ${item.color}`} />
                         <button type='button' >view case</button>
                    </a>

                    <div className="portfolio-item__img">
                        <img src={item.img} alt={item.name} />
                    </div>

                    <div className="portfolio-item__content">
                        <div className="text">
                            <p className={`body4 ${item.color} bold`}>{item.name}</p>
                            <p className="body5">{item.description}</p>
                        </div>
                        
                        <div className="tags">
                            {item.tags.map((tag, index2) => (
                                <div className={`tag tag-${item.color}`} key={index2}>
                                    <span key={index2} >{tag}</span>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            ))
        }
      </div>
  )

export default Portfolio;